import React from "react"
import { Link } from "gatsby"
import { Styled } from "theme-ui"
import Header from "../components/Header"
import Footer from "../components/Footer"
import "./index.css"
import { Helmet } from "react-helmet"

export default ({data}) => {

  return (
    <Styled.root>
      <main>
        <div class="container">
        <Helmet><title>Your Local Area - Home</title>
        <html lang={`en`} />
            <meta
                 name="description"
                 content={`Your Local Area home page.`}
            />
            </Helmet>
        <div class="centreMe">
            <Header />
            <h1>Your Local Area</h1>
            <p>This website displays housing prices from 1995 - present for regions, counties, towns and cities in England and Wales.</p>
            <p>All data comes from the HM Land Registry's <a href="https://www.gov.uk/government/collections/price-paid-data">Price Paid Data</a>.</p>
            <p>The data on this website is updated every month.</p>
            <p>All areas that have been covered can be found on the <Link to="/areas">Areas page</Link>.</p>
            <Footer />
            </div>
            </div>
      </main>
    </Styled.root>
  )
}
